import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { MdCheck } from 'react-icons/md'
import { IoBed, IoBedSharp } from 'react-icons/io5'

import {
  Stack,
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  useTheme,
  Divider,
  Tag,
  TagLabel,
} from '@chakra-ui/react'

import Layout from '../components/layout'

import { Article, H1, H2, Section, Gallery, P, FeatureItem, H3 } from '../ui'
import ButtonBook from '../components/buttonBook'
import { v4 } from 'uuid'

const FeatureRoomItem = ({ title, desc, icon }) => (
  <FeatureItem
    flex={{ base: '0 0 150px', lg: 'initial' }}
    spacing={1}
    margin={1}
    padding={4}
    boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
    borderRadius="15px"
    icon={icon}
    title={title}
    desc={<P lineHeight="shorter">{desc}</P>}
  />
)

function LayoutRoom({ title, desc, images }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const roomIcons = [
    <IoBedSharp color={theme.colors.primary[400]} size="2rem" />,
    <IoBedSharp color={theme.colors.primary[400]} size="2rem" />,
    <Stack isInline>
      <IoBed color={theme.colors.primary[400]} size="2rem" />
      <IoBed color={theme.colors.primary[400]} size="2rem" />
    </Stack>,
    <Stack isInline>
      <IoBed color={theme.colors.primary[400]} size="2rem" />
      <IoBed color={theme.colors.primary[400]} size="2rem" />
    </Stack>,
    <Stack isInline>
      <IoBed color={theme.colors.primary[400]} size="2rem" />
      <IoBed color={theme.colors.primary[400]} size="2rem" />
    </Stack>,
    <Stack isInline>
      <IoBed color={theme.colors.primary[400]} size="2rem" />
      <IoBed color={theme.colors.primary[400]} size="2rem" />
    </Stack>,
  ]

  return (
    <Layout variantHeader="solid" title={title}>
      <Article>
        <Section fullWidth paddingTop="0" paddingBottom="0">
          <Gallery images={images} buttonText={t('common:viewPhotos')} />
        </Section>

        <Section>
          <Flex direction={{ base: 'column', lg: 'row' }}>
            <Stack flex={6 / 8} marginRight={{ base: 0, lg: 10 }} spacing={5}>
              <Stack>
                <H1>{title}</H1>
                <P>{desc}</P>
              </Stack>
              <Divider />
              <H3>{t('rooms:spaces.title')}</H3>
              <Flex isInline flexWrap={{ lg: 'wrap' }} alignItems="center" overflowX="auto">
                {t('rooms:spaces.items', { returnObjects: true }).map((text) => (
                  <Tag
                    key={v4()}
                    size="lg"
                    flexShrink={0}
                    colorScheme="gray"
                    marginRight={2}
                    marginBottom={2}
                  >
                    <TagLabel>{text}</TagLabel>
                  </Tag>
                ))}
              </Flex>
              {/* <FeatureList
                spacing={{ base: 3, lg: 5 }}
                display={{ base: 'flex', lg: 'grid' }}
                overflowX={{ base: 'auto', lg: 'initial' }}
              >
                {t('rooms:room.rooms.items', { returnObjects: true }).map((room, index) => (
                  <FeatureRoomItem
                    key={v4()}
                    icon={roomIcons[index]}
                    title={room.title}
                    desc={<P lineHeight="shorter">{room.desc}</P>}
                  />
                ))}
              </FeatureList> */}
              <Divider />
              <H3>{t('rooms:location.title')}</H3>
              <Flex>
                <Box
                  as="iframe"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14917.679812925922!2d-89.8053381!3d20.8147417!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x19c69c95e76925eb!2shacienda%20poxila!5e0!3m2!1sen!2smx!4v1623466237305!5m2!1sen!2smx"
                  width="100%"
                  height={{ base: '300px', lg: '400px' }}
                  frameBorder="0"
                  style={{ border: '0' }}
                  aria-hidden="false"
                />
              </Flex>
              {/* <Divider />
              <H3>{t('rooms:terms.title')}</H3>
              <Stack spacing={2}>
                {t('rooms:terms.terms', { returnObjects: true }).map((text) => (
                  <P key={v4()}>{text}</P>
                ))}
              </Stack> */}
            </Stack>

            <Stack
              position={{ md: 'sticky' }}
              top="20px"
              marginTop={{ base: 5, lg: 0 }}
              flex={2 / 8}
              width="100%"
              padding={4}
              borderRadius="15px"
              boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
              spacing={4}
              alignSelf="flex-start"
            >
              <ButtonBook width="100%" />
              <H2 fontWeight="bold">{t('rooms:amenities.title')}</H2>
              <List>
                {t('rooms:amenities.items', { returnObjects: true }).map((text) => (
                  <ListItem display="flex" alignItems="center">
                    <ListIcon as={MdCheck} size="5rem" color="green.500" />
                    <P>{text}</P>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Flex>
        </Section>
      </Article>
    </Layout>
  )
}

LayoutRoom.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  images: PropTypes.array,
}

LayoutRoom.defaultProps = {
  title: '',
  desc: '',
  images: [],
}

export default LayoutRoom
