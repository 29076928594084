import React from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { useTheme } from '@chakra-ui/react'

import { Image } from '../../ui'
import LayoutRoom from '../../components/layoutRoom'

function ChanPoxila({ data }) {
  const { t } = useTranslation()

  const roomT = t('rooms:rooms', { returnObjects: true })[4]

  return (
    <LayoutRoom
      title={roomT.title}
      desc={roomT.desc}
      images={data.gallery.edges.map((image) => (
        <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
      ))}
    />
  )
}

export const query = graphql`
  query {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "rooms/chan-poxila" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default ChanPoxila
